<template lang="pug">
	main#listagemServicos
		BannerGeral(:contents="bannerContent", v-if="!$store.state.loading")
		Listagem(:contents="resultados", v-if="!$store.state.loading")
</template>

<script>
import pagina from '@/mixins/pagina'
import qs from 'qs'
import Listagem from '@sections/ListagemGeral/Listagem/Listagem'

export default {
	name: 'view-listagem-geral',
	mixins: [pagina],
	components: {
		Listagem
	},
	data () {
		return {
			resultados: []
		}
	},
	metaInfo () {
		return {
			title: `Resultados de busca para: "${this.$route.query.search}"`
		}
	},
	computed: {
		bannerContent () {
			return {
				content: {
					title: { pt: `Resultados de busca para: "${this.$route.query.search}"` }
				}
			}
		}
	},
	beforeRouteUpdate (to, from, next) {
		this.resultados = []
		this.loadQuery(to.query.search)
		next()
	},
	created () {
		this.loadQuery(this.$route.query.search)
	},
	methods: {
		loadQuery (q) {
			this.$axios
				.get(`search?${qs.stringify({ q })}`)
				.then(response => this.resultados = response.data)
		}
	}
}
</script>

<style lang="stylus" scoped src="./ListagemGeral.styl"></style>
