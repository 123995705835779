<template lang="pug">
    section#listagem
        .wrapper
            ul
                li(v-for="item in contents")
                    //- .tags
                        .tag
                            i
                            span Geoprocessamento
                        .tag
                            i
                            span Geoprocessamento
                    .header
                        h3 {{ item.title }}
                        //- span.flag @ Serviço Online
                    .body
                        p {{ item.description }}
                    .footer
                        router-link(:to="{ name: getPageType(item.namespace), params: { slug: item.slug } }").detalhes Mais detalhes
                        //- a(href="#").acessar
                            FontAwesomeIcon(:icon="icons.faArrowUpRightFromSquare")
                            |Acessar serviço
            //- button.more Mais resultados

</template>

<script>
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { props } from '@/mixins/components'

export default {
    name: "section-listagem",
    props,
    data() {
        return {
            icons: {
                faArrowUpRightFromSquare,
            },
        }
    },
    methods: {
        getPageType(type) {
            switch (type) {
                case 'App/Models/Noticia':
                case 'App\\Models\\Noticia': return 'Noticia'
                case 'App/Models/Service':
                case 'App\\Models\\Service': return 'Servico'
                case 'App/Models/Pagina':
                case 'App\\Models\\Pagina':
                default: return 'Pagina'
            }
        }
    },
}
</script>

<style lang="stylus" scoped src="./Listagem.styl"></style>
